<script setup lang="ts">
/*import { defineProps } from 'o365.vue.ts';*/
import { ODatePicker } from 'o365-ui-components';

const props = defineProps<DataEditCardFieldProps>();
</script>

<template>
    <div class="form-group mt-2 d-flex flex-column" 
        :class="{'flex-row-reverse': type == 'bit', 'justify-content-end': type == 'bit', 'mt-4': type == 'bit', 'align-items-center': type == 'bit'}">
        <label :for="field" class="form-label" :title="title">{{ label ?? field }}</label>
        <slot v-bind="props">
            <input v-if="type == 'string' && length < 300 && length != -1" :id="field" class="form-control" type="text" v-model="row[field]" :disabled="disabled" :title="title">
            <textarea v-if="type == 'string' && (length >= 300 || length == -1)" :id="field" class="form-control" rows="1" v-model="row[field]" :disabled="disabled" :title="title"></textarea>
            <input v-else-if="type == 'number'" :id="field" class="form-control" type="number" v-model="row[field]" :disabled="disabled" :title="title">
            <input v-else-if="type == 'bit'" :id="field" type="checkbox" v-model="row[field]" class="form-check-input me-2" :disabled="disabled" :title="title">
            <ODatePicker
                v-else-if="type == 'datetime'"
                :id="field"
                class="form-control"
                v-model="row[field]"
                timepicker
                :disabled="disabled" 
            />
        </slot>
    </div>
</template>

<script lang="ts">
export interface DataEditCardFieldProps {
    field: string;
    row: any;
    type: string;
    label?: string;
    disabled?: boolean;
    length : number;
    title? : string;
}
</script>

<style scoped>
    .form-label {
        font-size: 14px !important;
        margin-bottom: 2px;
    }
</style>